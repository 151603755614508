function translate(command, locale = 'de') {
  const translations = {
    de: {
      footer: {
        about: {
          headline: 'Hej, wir sind Julia&Isa!',
          copy: `Zucker&Jagdwurst ist unser veganer Foodblog.\nSchön, dass du da bist! [Mehr über uns.](/de/ueber-uns)`
        },
        legal: {
          copyright: `&copy; ${new Date().getFullYear()} Zucker&Jagdwurst. Alle Rechte vorbehalten.`
        }
      },
      general: {
        ad: 'Werbung',
        recentPosts: 'Aktuelle Beiträge',
        showAll: 'Alle Beiträge anzeigen',
        showAllShort: 'Alle Rezepte',
        language: {
          banner: {
            copy: 'Hoppala, du bist gerade auf unserer englischen Seite. Welche Sprache möchtest du für unsere Seite verwenden?',
            label: 'Weiter'
          }
        },
        cookie: {
          banner: {
            headline: 'Wir verwenden Cookies!',
            copy: 'Diese Webseite verwendet Cookies, die notwendig sind, um die Webseite zu nutzen. Weiterhin verwenden wir Dienste von Drittanbietern, die uns helfen, unser Webangebot zu verbessern (Website-Optmierung). Für die Verwendung bestimmter Dienste, benötigen wir deine Einwilligung. Die Einwilligung kannst du jederzeit widerrufen oder anpassen. Weitere Informationen findest du in unserer [Datenschutzerklärung](/de/datenschutz) oder im [Impressum](/de/impressum).',
            showDetails: 'Einstellungen anzeigen',
            sections: {
              mandatory: {
                headline: 'Notwendige Cookies',
                copy: 'Notwendige Cookies ermöglichen grundlegende Funktionen und sind für das einwandfreie Funktionieren der Website notwendig.'
              },
              stats: {
                headline: 'Statistik (Google Analytics & Hotjar)',
                copy: 'Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere BesucherInnen unsere Website nutzen. Google Analytics und Hotjar verwenden wir, um BesucherInnen-Daten statistisch auszuwerten.'
              },
              services: {
                headline: 'Dienste (YouTube, Mailchimp, Google Maps)',
                copy: 'Wir bieten Dienste von anderen Unternehmen (Dritten) an, wie z.B. Google Maps für Kartenansichten, Mailchimp für die Eintragung in unseren Newsletter oder YouTube für die Anzeige von Videos. Wenn diese Cookies deaktiviert sind, können diese Inhalte nicht angezeigt werden.'
              }
            },
            buttons: {
              confirmAll: 'Alle akzeptieren',
              confirmEssential: 'Nur essenzielle Cookies akzeptieren',
              settings: 'Cookie-Einstellungen'
            }
          }
        }
      },
      home: {
        recentPost: {
          buttonLabel: {
            post: 'Zum Beitrag',
            recipe: 'Zum Rezept',
            page: 'Mehr Informationen'
          }
        },
        posts: {
          headline: 'Aktuelle Rezepte'
        }
      },
      newsletter: {
        headline: 'Abonniere unseren Newsletter!',
        copy: 'Erhalte regelmäßige Updates aus unserer Küche, Rezeptempfehlungen und Überraschungen. Wir freuen uns auf dich!',
        cta: 'Eintragen'
      },
      recipe: {
        ingredients: 'Zutaten',
        instructions: 'Zubereitung',
        more: 'Mehr',
      },
      search: {
        field: {
          placeholder: 'Worauf hast du Appetit?'
        },
        filter: {
          labelShow: 'Erweiterte Suche einblenden',
          labelHide: 'Erweiterte Suche ausblenden',
        },
        filters: {
          categories: {
            label: 'Kategorien',
            breakfast: 'Frühstück',
            saladsSoup: 'Salate & Suppen',
            snacks: 'Snacks',
            main: 'Hauptgerichte',
            sweets: 'Kuchen & Süßes'
          },
          duration: {
            label: 'Zeit',
            20: 'Unter 20 Minuten',
            40: 'Unter 40 Minuten',
            60: 'Unter 60 Minuten',
            61: 'Über 60 Minuten'
          },
          allergens: {
            label: 'Allergene',
            glutenFree: 'Glutenfrei',
            soyFree: 'Sojafrei',
            withoutNuts: 'Nussfrei',
          },
          difficulty: {
            label: 'Schwierigkeit',
            easy: 'Einfach',
            medium: 'Mittel',
            difficult: 'Anspruchsvoll',
          }
        },
        results: {
          all: 'Alle Ergebnisse anzeigen',
          empty: 'Wir haben leider nichts gefunden. 🙁',
          searched: 'Du suchst nach:',
          loading: 'Ergebnisse werden geladen...',
          matches: 'Ergebnisse für'
        },
        section: {
          advanced: 'Erweiterte Suche',
          headline: 'Worauf hast du Appetit?',
          placeholder: 'Vegane Pancakes...',
          buttonLabel: 'Ergebnisse anzeigen'
        },
      },
      post: {
        writtenBy: 'geschrieben von'
      }
    },
    en: {
      footer: {
        about: {
          headline: 'Hej, we are Julia&Isa!',
          copy: `Zucker&Jagdwurst is our vegan food blog.\nGlad you're here! [More about us.](/en/about-us)`
        },
        legal: {
          copyright: `&copy; ${new Date().getFullYear()} Zucker&Jagdwurst. All rights reserved.`
        }
      },
      general: {
        ad: 'Ad',
        recentPosts: 'Recent Posts',
        showAll: 'Show all posts',
        showAllShort: 'Show all recipes',
        language: {
          banner: {
            copy: 'You are viewing our German page. Which language would you like to use?',
            label: 'Choose'
          }
        },
        cookie: {
          banner: {
            headline: 'We use cookies!',
            copy: 'This website uses cookies that are necessary to use the website. We also use third-party services that help us to improve our website (website optimization). We require your consent to use certain services. You can adjust your preferences at any time. Find more information in our privacy policy or imprint.',
            showDetails: 'Show settings',
            sections: {
              mandatory: {
                headline: 'Essential',
                copy: 'Essential cookies enable basic functions and are necessary for the proper functioning of our website.'
              },
              stats: {
                headline: 'Statistics (Google Analytics & Hotjar)',
                copy: 'For website optimization, we collect data that is already required for the technical provision of the website. Such data is only aggregated and made available to us as a statistical overview.'
              },
              services: {
                headline: 'Services & Social Media (YouTube, Mailchimp, Google Maps)',
                copy: 'We offer services from other companies (third parties), such as Google Maps for map views, Mailchimp for our newsletter or YouTube for displaying videos. If these cookies are deactivated, this content cannot be displayed.'
              }
            },
            buttons: {
              confirmAll: 'Accept all',
              confirmEssential: 'Only accept essential cookies',
              settings: 'Settings'
            }
          }
        }
      },
      home: {
        recentPost: {
          buttonLabel: {
            post: 'Show post',
            recipe: 'Show recipe',
            page: 'More information'
          }
        },
        posts: {
          headline: 'Recent posts'
        }
      },
      newsletter: {
        headline: 'Abonniere unseren Newsletter!',
        copy: 'Erhalte regelmäßige Updates aus unserer Küche, Rezeptempfehlungen und Überraschungen. Wir freuen uns auf dich!',
        cta: 'Eintragen'
      },
      recipe: {
        ingredients: 'Ingredients',
        instructions: 'Instructions',
        more: 'More',
      },
      search: {
        field: {
          placeholder: 'What are you in the mood for?'
        },
        filter: {
          labelShow: 'Show advanced search',
          labelHide: 'Hide advanced search',
        },
        filters: {
          categories: {
            label: 'Categories',
            breakfast: 'Breakfast',
            saladsSoup: 'Salads & Soups',
            snacks: 'Snacks',
            main: 'Main',
            sweets: 'Cakes & Sweets'
          },
          duration: {
            label: 'Time',
            20: 'Under 20 Minutes',
            40: 'Under 40 Minutes',
            60: 'Under 60 Minutes',
            61: 'Over 60 Minutes'
          },
          allergens: {
            label: 'Allergens',
            glutenFree: 'Gluten-free',
            soyFree: 'Soy-free',
            withoutNuts: 'Nut-free',
          },
          difficulty: {
            label: 'Difficulty',
            easy: 'Easy',
            medium: 'Medium',
            difficult: 'Ambitious',
          }
        },
        results: {
          all: 'See all search results',
          empty: 'There are no matches. 🙁',
          loading: 'Results are loading...',
          matches: 'results for'
        },
        section: {
          advanced: 'Advanced search',
          headline: 'What are you in the mood for?',
          placeholder: 'Vegan pancakes...',
          buttonLabel: 'Show results'
        }
      },
      post: {
        writtenBy: 'written by'
      }
    }
  }

  try {
    return `${locale}.${command}`.split('.').reduce((o, i) => o[i], translations)
  } catch (e) {
    return 'translation missing'
  }
}

module.exports.translate = translate
module.exports.t = translate
