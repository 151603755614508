import * as styles from './trigger.module.scss'

import React from 'react'

const PopupTrigger = ({ open = false, onToggle }) => {
  const label = open ? '⤬' : '📖'
  return (
    <button className={styles.button} onClick={onToggle} data-open={open}>{label}</button>
  )
}

export default PopupTrigger
